<!-- Loading spinner -->
@if (loading) {
<app-dot-loader-scale class="offers-loader-scale"></app-dot-loader-scale>
} @else {
<mat-tab-group mat-stretch-tabs="true" mat-align-tabs="start" [(selectedIndex)]="selectedIndex" animationDuration="0ms"
    (selectedTabChange)="handleSelectedTabChange($event)">
    <!-- Details tab -->
    <mat-tab>
        <ng-template mat-tab-label>Details</ng-template>
        <!-- Each content section must be an ng-template to refresh on tab switch. -->
        <ng-template matTabContent>
            <div class="grid grid-flow-col grid-cols-[1fr_352px]">
                <div appDynamicScreenHeight [dshdConfig]="{ scrollable: true }" class="p-4">
                    <mat-card appearance="outlined">
                        <mat-card-content>
                            <app-details [applicationDetails]="applicationDetails$ | async"
                                [applicationFields]="applicationFields$ | async">
                            </app-details>
                        </mat-card-content>
                    </mat-card>
                </div>

                <!-- Deal sidebar -->
                <app-deal-sidebar [dealId]="dealId" [applicationDetailsPage]="(applicationDetails$ | async).page"
                    (openDealHistoryButtonClicked)="handleOpenDealHistoryClicked()"
                    (overrideButtonClicked)="handleOverrideButtonClicked()"
                    (pushToLaserProButtonClicked)="handlePushToLaserProButtonClicked()"
                    (retryDecisioningClicked)="handleRetryDecisioningClicked()"
                    (applicationDetailsTabChange)="activateTabFromName($event)">
                </app-deal-sidebar>
            </div>
        </ng-template>
    </mat-tab>

    <!-- Documents tab -->
    <mat-tab>
        <ng-template mat-tab-label>
            Documents
            <span *ngIf="documents$ | async as documents" class="!font-light !text-gray-400 ml-2">
                {{ documents?.length }}
            </span>
        </ng-template>
        <ng-template matTabContent>
            <div class="grid grid-flow-col grid-cols-[1fr_352px]">
                <div appDynamicScreenHeight [dshdConfig]="{ scrollable: true }" class="p-4">
                    <mat-card appearance="outlined">
                        <mat-card-content>
                            <app-documents-smart [dealId]="dealId" [borrowerId]="borrowerId"
                                [borrowerLenderId]="businessLenderId$ | async">
                            </app-documents-smart>
                        </mat-card-content>
                    </mat-card>
                </div>
                <app-deal-sidebar [dealId]="dealId" [applicationDetailsPage]="(applicationDetails$ | async).page"
                    (openDealHistoryButtonClicked)="handleOpenDealHistoryClicked()"
                    (overrideButtonClicked)="handleOverrideButtonClicked()"
                    (pushToLaserProButtonClicked)="handlePushToLaserProButtonClicked()"
                    (retryDecisioningClicked)="handleRetryDecisioningClicked()"
                    (applicationDetailsTabChange)="activateTabFromName($event)">
                </app-deal-sidebar>
            </div>
        </ng-template>
    </mat-tab>

    <!-- Offers tab -->
    <mat-tab>
        <ng-template mat-tab-label>
            Offers
            <ng-container *ngIf="offers$ | async as offers">
                <span class="!font-light !text-gray-400 ml-2">
                    @if (offers.length) {
                    {{ offers.length }}
                    } @else {
                    0
                    }
                </span>
            </ng-container>
        </ng-template>
        <ng-template matTabContent>
            <div class="grid grid-flow-col grid-cols-[1fr_352px]">
                <div appDynamicScreenHeight [dshdConfig]="{ scrollable: true }" class="p-4">
                    <app-offers-smart [dealId]="dealId" [loanProductCategory]="loanProductCategory"
                        [tenantId]="businessTenantId$ | async">
                    </app-offers-smart>
                </div>
                <app-deal-sidebar [dealId]="dealId" [applicationDetailsPage]="(applicationDetails$ | async).page"
                    (openDealHistoryButtonClicked)="handleOpenDealHistoryClicked()"
                    (overrideButtonClicked)="handleOverrideButtonClicked()"
                    (pushToLaserProButtonClicked)="handlePushToLaserProButtonClicked()"
                    (retryDecisioningClicked)="handleRetryDecisioningClicked()"
                    (applicationDetailsTabChange)="activateTabFromName($event)">
                </app-deal-sidebar>
            </div>
        </ng-template>
    </mat-tab>

    <!-- Approvals tab -->
    <mat-tab>
        <ng-template mat-tab-label>Approval</ng-template>
        <ng-template matTabContent>
            <div class="grid grid-flow-col grid-cols-[1fr_352px]">
                <div appDynamicScreenHeight [dshdConfig]="{ scrollable: true }">
                    <app-approval-smart [dealId]="dealId" [useStoreApprovals]="true">
                    </app-approval-smart>
                </div>
                <app-deal-sidebar [dealId]="dealId" [applicationDetailsPage]="(applicationDetails$ | async).page"
                    (openDealHistoryButtonClicked)="handleOpenDealHistoryClicked()"
                    (overrideButtonClicked)="handleOverrideButtonClicked()"
                    (pushToLaserProButtonClicked)="handlePushToLaserProButtonClicked()"
                    (retryDecisioningClicked)="handleRetryDecisioningClicked()"
                    (applicationDetailsTabChange)="activateTabFromName($event)">
                </app-deal-sidebar>
            </div>
        </ng-template>
    </mat-tab>

    <!-- Decisioning tab -->
    @if (hasDecisioningFeature$ | async) {
    <mat-tab>
        <ng-template mat-tab-label>Decisioning</ng-template>
        <ng-template matTabContent>
            <div class="grid grid-flow-col grid-cols-[1fr_352px]">
                <div appDynamicScreenHeight [dshdConfig]="{ scrollable: true }">
                    <app-decisioning [borrowerId]="borrowerId" [loanProductId]="loanProductId"
                        [deal]="(applicationDetails$ | async).deal"
                        [autoPopOverrides]="shouldAutoPopOverrides"></app-decisioning>
                </div>
                <app-deal-sidebar [dealId]="dealId" [applicationDetailsPage]="(applicationDetails$ | async).page"
                    (openDealHistoryButtonClicked)="handleOpenDealHistoryClicked()"
                    (overrideButtonClicked)="handleOverrideButtonClicked()"
                    (pushToLaserProButtonClicked)="handlePushToLaserProButtonClicked()"
                    (retryDecisioningClicked)="handleRetryDecisioningClicked()"
                    (applicationDetailsTabChange)="activateTabFromName($event)">
                </app-deal-sidebar>
            </div>
        </ng-template>
    </mat-tab>
    }
    <!-- Contract request tab -->
    @if (showContractRequestTab) {
    <mat-tab>
        <ng-template mat-tab-label>
            Contract request
            <span class="!font-light !text-gray-400 ml-2">
                @if (hasContractRequest) {
                1
                } @else {
                0
                }
            </span>
        </ng-template>
        <ng-template matTabContent>
            <div class="grid grid-flow-col grid-cols-[1fr_352px]">
                <div appDynamicScreenHeight [dshdConfig]="{ scrollable: true }">
                    <app-contract-requests *ngIf="contractRequests$ | async as contractRequest; else loading"
                        [contractRequest]="contractRequest" [loanProductName]="loanProductTypeName ?? loanProductType"
                        [dealId]="dealId">
                    </app-contract-requests>
                    <ng-template #loading>
                        <app-dot-loader-scale class="!my-0 !mx-auto"></app-dot-loader-scale>
                    </ng-template>
                </div>
                <app-deal-sidebar [dealId]="dealId" [applicationDetailsPage]="(applicationDetails$ | async).page"
                    (openDealHistoryButtonClicked)="handleOpenDealHistoryClicked()"
                    (overrideButtonClicked)="handleOverrideButtonClicked()"
                    (pushToLaserProButtonClicked)="handlePushToLaserProButtonClicked()"
                    (retryDecisioningClicked)="handleRetryDecisioningClicked()"
                    (applicationDetailsTabChange)="activateTabFromName($event)">
                </app-deal-sidebar>
            </div>
        </ng-template>
    </mat-tab>
    }
    <!-- History tab -->
    <mat-tab>
        <ng-template mat-tab-label>
            History
        </ng-template>
        <ng-template matTabContent>
            <div class="deal-history-content">
                <div appDynamicScreenHeight [dshdConfig]="{ scrollable: true }">
                    <app-deal-activities *ngIf="dealActivities$ | async as dealActivities; else loading"
                        [dealActivities]="dealActivities"
                        [dealId]="dealId">
                    </app-deal-activities>
                    <ng-template #loading>
                        <app-dot-loader-scale class="!my-0 !mx-auto"></app-dot-loader-scale>
                    </ng-template>
                </div>
            </div>
        </ng-template>
    </mat-tab>
</mat-tab-group>
}
