import {Action, createSelector, Select, Selector, State, StateContext, Store} from '@ngxs/store';
import {Inject, Injectable} from '@angular/core';
import {patch} from '@ngxs/store/operators';
import {
  ClearAuthAlert,
  ForgotPassword,
  GetAuthUser,
  Hijack,
  Logout,
  RestoreLocalStorageUser,
  ResetPassword,
  SetAuthAlert,
  SetAuthUser,
  UpdateAuthUser,
  ResetExpiredPassword
} from './auth.actions';
import {AuthUser} from './auth-user';
import * as FullStory from '@fullstory/browser';
import Rollbar from 'rollbar';
import {RollbarService} from '@app/app/rollbar';
import {PendoService} from '@app/app/services/pendo.services';
import {AuthService} from '@app/app/auth/auth.service';
import {catchError, Observable, take, tap} from 'rxjs';
import {ClearScopeStore, GetScope} from '../scopes/scope.actions';
import {ClearSaasFeaturesStore, GetSaasFeatures} from '../saas-features/saas-features.actions';
import {BusinessesListState} from '../businesses/businesses-list.state';
import {LoginResponse} from '@app/app/auth/login-response';
import {LoginUser} from '@app/app/interfaces/login-user.model';
import {
  LendioAngularMaterialThemeService
} from '@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service';
import { ClearAlertsStore } from '../global-alerts/global-alerts.actions';
import {ClearApplications} from '../applications-list/applications-list.actions';
import {ClearLabels} from '../labels/labels.actions';
import {ClearPortalUsers} from '../portal-users/portal-users.actions';
import {ClearNotificationStore} from '../notifications/notifications.actions';
import {ClearSettingsStore} from '../settings/settings.actions';
import {ClearDarkLaunchOpportunities, ClearOpportunitiesStore} from '../opportunities/opportunities.actions';
import {ClearOpportunitiesGridState} from '../opportunitiesGrid/opportunities-grid.actions';
import {ClearEmbeddedStore} from '../embedded/embedded.actions';
import {MatDialog} from '@angular/material/dialog';
import {NotificationService} from '@app/app/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import {SessionService} from '@app/app/auth/session.service';
import {Alert} from '@app/app/interfaces/alert.model';
import {ClearThoughtSpotReportsStore, LogOutOfThoughtSpot} from '../thoughtspot-reports/thoughtspot-reports.actions';
import {environment} from '@app/environments/environment';
import { LoadingService } from '@app/app/services/loading.service';
import { SaasFeaturesState } from '../saas-features/saas-features.state';
import { ClearFunnelDealsStore, StopFunnelDealsRequests } from '../funnel-deals/funnel-deals.actions';
import { ClearFunnelGridState } from '@app/app/store/funnel-deals-grid/funnel-deals-grid.actions';
import { ClearFunnelViewsStore } from '../funnel-views/funnel-views.actions';
import { LogEvent } from '../tracking/tracking.actions';

export class AuthStateModel {
  user: AuthUser;
  isHijacked: boolean;
  alert: Alert | null;
}

@State<Partial<AuthStateModel>>({
  name: 'auth',
  defaults: {
    isHijacked: false,
    alert: null,
  }
})

@Injectable()
export class AuthState {
  @Select(BusinessesListState.countInitialized) businessCountInitialized$: Observable<boolean>;

  @Selector()
  static user(state: AuthStateModel) {
    return state.user;
  }

  @Selector()
  static isHijacked(state: AuthStateModel) {
    return state.isHijacked;
  }

  @Selector()
  static alert(state: AuthStateModel) {
    return state.alert;
  }

  @Selector()
  static userHasPermission(permissionName: string) {
    return createSelector([AuthState], ({auth}) => {
      return auth?.user?.permissionsList?.includes(permissionName);
    });
  }

  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private auth: AuthService,
    private pendoService: PendoService,
    private store: Store,
    private themeService: LendioAngularMaterialThemeService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private router: Router,
    private sessionService: SessionService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
  ) {
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    this.clearData();
    const user = ctx.getState().user;
    if (user) {
      this.dialog.closeAll();
      this.notificationService.stopPollingNotifications();
      this.notificationService.stopPollingCount();
      ctx.dispatch(new LogOutOfThoughtSpot());
      localStorage.removeItem('PortalUser');
      localStorage.removeItem('HijackUser');
      localStorage.removeItem('Session');
      localStorage.removeItem('Home');
      localStorage.removeItem('SessionWarningDismissed');
      this.router.navigateByUrl('/auth/login').finally(() => {
        ctx.setState(
          patch<AuthStateModel>({
            user: undefined,
            isHijacked: false,
          })
        );
      });
    }
    this.auth.identityLogout().pipe(take(1)).subscribe();
  }

  @Action(Hijack)
  hijack(ctx: StateContext<AuthStateModel>, {userId}: Hijack) {
    return this.auth.hijack(userId).pipe(tap((response) => {
      ctx.dispatch(new SetAuthUser(this.formatLoginUser(response), true));
    }));
  }

  @Action(RestoreLocalStorageUser)
  restoreLocalStorageUser(ctx: StateContext<AuthStateModel>) {
    const portalUser = localStorage.getItem('PortalUser');
    const hijackUser = localStorage.getItem('HijackUser');
    if (!portalUser && !hijackUser) {
      return;
    }
    const currentUrl = new URL(window.location.href);
    sessionStorage.setItem('redirect', currentUrl.pathname + currentUrl.search);

    if (hijackUser) {
      ctx.dispatch(new SetAuthUser(JSON.parse(hijackUser), true));
    } else if (portalUser) {
      ctx.dispatch(new SetAuthUser(JSON.parse(portalUser)));
    }
  }

  @Action(GetAuthUser)
  getAuthUser(ctx: StateContext<AuthStateModel>) {
    return this.auth.getUser().pipe(tap((response) => {
      ctx.dispatch(new SetAuthUser(this.formatLoginUser(response)));
    }));
  }

  @Action(SetAuthUser)
  setAuthUser(ctx: StateContext<AuthStateModel>, {user, isHijacked = false}: SetAuthUser) {
    const previousUser = ctx.getState().user;
    if (previousUser) {
      ctx.dispatch(new LogOutOfThoughtSpot());
      this.clearData();
    }
    const authUser = new AuthUser(user);
    ctx.setState(
      patch<AuthStateModel>({
        user: authUser,
        isHijacked,
      })
    );
    this.setTheme(authUser);
    this.sessionService.setTimeout();
    this.setInLocalStorage(user, isHijacked);
    this.thirdPartyServiceInit(authUser, isHijacked);
    this.hydrateRelatedDataAndNavigate(authUser);
    this.store.dispatch(new LogEvent('userAuthenticated', {}));
  }

  @Action(UpdateAuthUser)
  updateAuthUser(ctx: StateContext<AuthStateModel>, {updates}: UpdateAuthUser) {
    if (updates.permissionsList) {
      updates.permissions = this.generateUserPermissions(updates.permissionsList)
    }
    const updatedUser = new AuthUser({
      ...updates,
      ...ctx.getState().user,
    });
    ctx.setState(
      patch<AuthStateModel>({
        user: updatedUser,
      })
    );
    this.setInLocalStorage(updatedUser, ctx.getState().isHijacked);
  }

  @Action(ResetPassword)
  resetPassword(ctx: StateContext<AuthStateModel>, {email, token, password}: ResetPassword) {
    return this.auth.resetPassword(email, token, password).pipe(tap((response) => {
      ctx.dispatch(new SetAuthAlert({
        level: 'success',
        message: 'Password reset successfully. Please login to continue.'
      }));
      this.router.navigate(['/auth/login']);
    }), catchError((err: any) => {
      const errorMsg = err.error.errors[0].message;
      this.store.dispatch(new SetAuthAlert({
        level: 'error',
        message: errorMsg
      }));
      throw err;
    }));
  }

  @Action(ResetExpiredPassword)
  resetExpiredPassword(ctx: StateContext<AuthStateModel>, {oldPassword, newPassword, confirmPassword, transactionId}: ResetExpiredPassword) {
    return this.auth.resetExpiredPassword({
      oldPassword,
      newPassword,
      confirmPassword,
      transactionId
    }).pipe(tap((response) => {
      if (response.data.status === 'success') {
        this.store.dispatch(new ClearAuthAlert());
        this.store.dispatch(new GetAuthUser());
        this.loadingService.setLoading(true);
      } else {
        this.store.dispatch(new SetAuthAlert({
          level: 'error',
          message: 'Failed to reset password.'
        }));
        this.router.navigate(['/auth/login']);
      }
    }), catchError((err: any) => {
      let errorMsg = err?.error?.errors?.join(', ') ?? 'Failed to reset password.';
      this.store.dispatch(new SetAuthAlert({
        level: 'error',
        message: errorMsg
      }));
      throw err;
    }));
  }

  @Action(ForgotPassword)
  forgotPassword(ctx: StateContext<AuthStateModel>, {email}: ForgotPassword) {
    return this.auth.forgotPassword(email).pipe(tap(() => {
      ctx.dispatch(new SetAuthAlert({
        level: 'success',
        message: 'We\'ve sent you an email with instructions on how to create a new password.',
      }));
    }), catchError((err: any) => {
      ctx.dispatch(new SetAuthAlert({
        level: 'error',
        message: 'An unknown error occurred, please try again momentarily.'
      }));
      throw err;
    }));
  }

  @Action(SetAuthAlert)
  setAuthAlert(ctx: StateContext<AuthStateModel>, {alert}: SetAuthAlert) {
    ctx.setState(
      patch<AuthStateModel>({
        alert,
      })
    );
  }

  @Action(ClearAuthAlert)
  clearAuthAlert(ctx: StateContext<AuthStateModel>) {
    ctx.setState(
      patch<AuthStateModel>({
        alert: null,
      })
    );
  }

  private postLoginNavigation() {
    const redirect = sessionStorage.getItem('redirect');
    if (redirect && redirect !== 'auth/login') {
      const decodedRedirect = decodeURIComponent(redirect);
      sessionStorage.removeItem('redirect');
      return this.router.navigateByUrl(decodedRedirect);
    } else if (localStorage.getItem('Home')) {
      return this.router.navigate([localStorage.getItem('Home') as string])
    } else {
      return this.router.navigate(['/']);
    }
  }

  private setUserHome(user: AuthUser) {
    if (user) {
      localStorage.removeItem('Home');
      let home = '';
      if (user.ppp) {
        home = 'applications/new-apps';
      } else if (user.role === 'affiliate') {
        if (this.store.selectSnapshot(SaasFeaturesState.saasPermitted('dashboardAffiliateBase')) || this.store.selectSnapshot(SaasFeaturesState.saasPermitted('dashboardPartnerBase'))) {
          home = 'partner-dashboard'
        } else {
          home = 'clients';
        }
      } else {
        home = 'dashboard';
      }
      localStorage.setItem('Home', home);
    }
  }

  private thirdPartyServiceInit(user: AuthUser, isHijacked: boolean = false) {
    if (!isHijacked) {
      this.setAsFullStoryUser(user);
      this.setAsRollbarUser(user);
      this.pendoService.identify(user);
    }
  }

  private setInLocalStorage(user: LoginUser, isHijacked: boolean) {
    if (isHijacked) {
      localStorage.setItem('HijackUser', JSON.stringify(user));
    } else {
      localStorage.setItem('PortalUser', JSON.stringify(user));
    }
  }

  private formatLoginUser(data: LoginResponse): LoginUser {
    const resData = data.data;
    const resUser = resData?.userData;
    const loginUser: LoginUser = {};

    loginUser.id = resUser?.id;
    loginUser.calculatedPermissions = resUser?.calculatedPermissions;
    loginUser.email = resUser?.email;
    loginUser.first = resUser?.first;
    loginUser.last = resUser?.last;
    loginUser.fullName = resUser?.first + ' ' + resUser?.last;
    loginUser.role = resUser?.role;
    loginUser.institution = {
      acceptsOtherLeads: resUser?.acceptsOtherLeads,
      id: resUser?.institutionId,
      name: resUser?.institutionName,
      walleUrl: resUser?.institutionWalleUrl,
      loanProducts: resUser?.loanProducts,
      isPPPLender: resUser?.isPPPLender,
      sbaTokenGuarded: resUser?.institutionSbaTokenGuarded,
      pppSbaSubmissionDisabled: resUser?.pppSbaSubmissionDisabled,
    };
    loginUser.password = '';
    loginUser.permissions = {
      canHijack: resUser?.canDoHijack,
      canManageLenderUsers: resUser?.canManageLenderUsers,
      canUploadLoanForgivenessCSV: resUser?.canUploadLoanForgivenessCSV,
      canViewRequests: resUser?.canViewRequests,
      canFund: resUser?.canFund,
      hasWalleAccount: resUser?.hasWalleAccount
    };
    loginUser.permissionsList = resUser?.permissionsList;
    loginUser.ppp = resUser?.isPPPLender;

    loginUser.affiliate = resUser?.affiliate ? {
      id: resUser?.affiliate.affiliateId,
      name: resUser?.affiliate.name,
      medium: resUser?.affiliate.medium,
    } : null;

    loginUser.affiliate_children = resUser?.affiliate_children
      ? resUser?.affiliate_children
      : []

    loginUser.tenantId = resUser?.tenantId;

    return loginUser;
  }

  private clearData() {
    this.store.dispatch(new ClearApplications());
    this.store.dispatch(new ClearLabels());
    this.store.dispatch(new ClearPortalUsers());
    this.store.dispatch(new ClearNotificationStore());
    this.store.dispatch(new ClearSettingsStore());
    this.store.dispatch(new ClearOpportunitiesStore());
    this.store.dispatch(new ClearOpportunitiesGridState());
    this.store.dispatch(new ClearEmbeddedStore());
    this.store.dispatch(new ClearDarkLaunchOpportunities());
    this.store.dispatch(new ClearSaasFeaturesStore());
    this.store.dispatch(new ClearScopeStore());
    this.store.dispatch(new ClearThoughtSpotReportsStore());
    this.store.dispatch(new ClearAlertsStore());
    this.store.dispatch(new StopFunnelDealsRequests());
    this.store.dispatch(new ClearFunnelDealsStore());
    this.store.dispatch(new ClearFunnelGridState());
    this.store.dispatch(new ClearFunnelViewsStore());
  }

  private setTheme(user: AuthUser) {
    const themeEnabledState = localStorage.getItem('lendio-angular-material-theme') ?? 'true';
    // Make sure staging cypress test users stay on old theme until cypress tests have been refactored for new theme.
    const cypressTestEmails = ['lpgal@lendio.com', 'pipelineguy@lendio.com', 'test_referral_partner@lendio.com'];
    const isCypressTestUser = cypressTestEmails.some(email => email === user.email);
    if (themeEnabledState === 'true' && !isCypressTestUser) {
      this.themeService.enable();
    } else {
      this.themeService.forceDisable();
    }
  }

  private hydrateRelatedDataAndNavigate(user: AuthUser) {
    this.store.dispatch(new GetScope('lenderSaas'));
    this.store.dispatch(new GetSaasFeatures()).pipe(take(1)).subscribe(() => {
      this.setUserHome(user);
      this.postLoginNavigation();
    });

    this.loadingService.setLoading(false);
  }

  private setAsFullStoryUser(user: AuthUser) {
    if (!environment.disableFullStory) {
      FullStory.identify(user.id?.toString() ?? '', {
        displayName: user.fullName,
        email: user.email,
        institution: {
          id: user.institution?.id,
          name: user.institution?.name
        }
      });
    }
  }

  private setAsRollbarUser(user: AuthUser) {
    this.rollbar.configure({
      payload: {
        person: {
          id: user!.id!,
          username: user?.fullName,
          email: user?.email,
          institution: {
            id: user?.institution?.id,
            name: user?.institution?.name
          }
        }
      }
    });
  }

  private generateUserPermissions(permissionsList: Array<string> = []) {
    const map: {
      [index: string]: string;
      lpxHijack: string;
      lpxManageLenderUsers: string;
      lpxViewRequests: string;
      lpxFundDeal: string;
      hasWalleAccount: string;
      canViewNotifications: string;
      canUploadLoanForgivenessCSV: string;
    } = {
      lpxHijack: 'canDoHijack',
      lpxManageLenderUsers: 'canManageLenderUsers',
      lpxViewRequests: 'canViewRequests',
      lpxFundDeal: 'canFund',
      hasWalleAccount: 'hasWalleAccount',
      canViewNotifications: 'canViewNotifications',
      canUploadLoanForgivenessCSV: 'canUploadLoanForgivenessCSV'
    }

    const permissions: {
      [index: string]: boolean;
      canDoHijack: boolean;
      canManageLenderUsers: boolean;
      canViewRequests: boolean;
      canFund: boolean;
      hasWalleAccount: boolean;
      canViewNotifications: boolean;
      canUploadLoanForgivenessCSV: boolean;
    } = {
      canDoHijack: false,
      canManageLenderUsers: false,
      canViewRequests: false,
      canFund: false,
      hasWalleAccount: false,
      canViewNotifications: false,
      canUploadLoanForgivenessCSV: false,
    }
    Object.keys(map).forEach((permission: string) => {
      if (permissionsList.includes(permission)) {
        const permissionsKey = map[permission];
        permissions[permissionsKey] = true;
      }
    })
    return permissions;
  }
}
