import {
  Component,
  ViewChild,
  AfterViewInit,
  OnInit,
  OnDestroy,
  ViewChildren,
  QueryList,
  HostListener,
  LOCALE_ID,
  DEFAULT_CURRENCY_CODE,
  computed,
} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MatSidenav, MatDrawer } from '@angular/material/sidenav';
import { environment } from '../environments/environment';
import { SessionService } from './auth/session.service';
import { SaasFeaturesService } from './services/saas-features.service';
import { LocaleSettingService } from './services/locale-setting-service';
import { NotificationService } from './services/notification.service';
import { Store, Select } from '@ngxs/store';
import { GlobalAlertsState } from './store/global-alerts/global-alerts.state';
import { Alert } from './interfaces/alert.model';
import { Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil, throttleTime } from 'rxjs/operators';
import { DismissAlert } from './store/global-alerts/global-alerts.actions';
import * as FullStory from '@fullstory/browser';
import { registerLocaleData } from '@angular/common';
import { PusherService } from './services/pusher.service';
import { LENDIO_BREAKPOINTS } from "@app/app/interfaces/lendio-breakpoints";
import { BreakpointObserver } from "@angular/cdk/layout";
import {
  LendioAngularMaterialThemeService
} from "@app/app/components/lendio-angular-material-theme/lendio-angular-material-theme.service";
import { NavPageTitleService } from "@app/app/services/nav-page-title.service";
import { BreadcrumbService } from './services/breadcrumb.service';
import {
  GetLenderDecisioningBatches,
  SubscribeToDecisioningBatchPusherUpdates
} from '@app/app/store/decisioning/decisioning.actions';
import { DecisioningState } from '@app/app/store/decisioning/decisioning.state';
import { DecisioningBatch } from '@app/app/interfaces/decisioning-batch.model';
import { AuthState } from '@app/app/store/auth/auth.state';
import {
  MatSnackBar,
  MatSnackBarRef,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { DecisioningSnackbarComponent } from '@app/app/components/decisioning-snackbar/decisioning-snackbar.component';
import { SaasFeaturesState } from './store/saas-features/saas-features.state';
import { LendioSnackbarService } from './services/lendio-snackbar.service';
import { UnsubscribeFromImportStateUpdates } from './store/lender-imports/import.actions';
import { DecisioningService } from './services/decisioning.service';
import { BusinessesListState } from './store/businesses/businesses-list.state';
import { AuthUser } from './store/auth/auth-user';
import { Logout, RestoreLocalStorageUser } from './store/auth/auth.actions';
import { FormOptionsService } from './services/form-options.service';
import { LoadingService } from './services/loading.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FunnelEventsService } from '@app/app/services/funnel-events.service';
import { LogEvent } from './store/tracking/tracking.actions';

declare var zE: any; //zE is a function on the window object
const APPS_WITH_TABS = [
  'dashboard',
  'renewals',
  'administration',
  'approval profile report',
  'settings',
  'import details',
  'makecsv',
  'funnel'
] as readonly string[];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', 'styles.temp.scss'],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [LocaleSettingService],
      useFactory: (localeSettingService: LocaleSettingService) => localeSettingService.getLocale()
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      deps: [LocaleSettingService],
      useFactory: (localeSettingService: LocaleSettingService) => localeSettingService.getCurrencyCode()
    }
  ],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(300)),
    ]),
  ],
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  newThemeEnabled = computed(() => this._themeService.newThemeEnabled());
  alerts$: Observable<Alert[] | null> = this.store.select(GlobalAlertsState.alerts);
  @Select(DecisioningState.decisioningProgress) decisioningProgress$: Observable<DecisioningBatch[]>;
  @Select(AuthState.user) currentUser$: Observable<AuthUser>;
  @Select(SaasFeaturesState.saasPermitted('businessList')) hasBusinessListFeature$: Observable<boolean>;
  @Select(SaasFeaturesState.saasPermitted('navigationAdministration', 'lpxNavigationAdministration.view')) adminVisible$: Observable<boolean>;
  @Select(SaasFeaturesState.saasPermitted('dashboardLenderBase')) hasLenderDashboardFeature$: Observable<boolean>;
  @Select(SaasFeaturesState.saasPermitted('dashboardAffiliateBase')) hasAffiliateDashboardFeature$: Observable<boolean>;
  @Select(SaasFeaturesState.saasPermitted('dashboardPartnerBase')) hasPartnerDashboardFeature$: Observable<boolean>;
  @Select(SaasFeaturesState.saasPermitted('funnelDealList')) hasFunnelDealListFeature$: Observable<boolean>;
  @Select(SaasFeaturesState.initialLoadComplete) sassFeaturesLoaded$: Observable<boolean>;
  @Select(SaasFeaturesState.saasPermitted('reportsList')) hasReportsListFeature$: Observable<boolean>;
  @Select(SaasFeaturesState.saasPermitted('decisioning')) hasDecisioningFeature$: Observable<boolean>;
  @Select(SaasFeaturesState.saasPermitted('funnel2.0')) hasFunnelEarlyAccess$: Observable<boolean>;
  @Select(BusinessesListState.countInitialized) businessCountInitialized$: Observable<boolean>;
  @Select(AuthState.userHasPermission('lpxNewBusinessSearch.read')) newBusinessSearch$: Observable<boolean>;

  @ViewChildren('notificationsidenav') notificationSidenav: QueryList<MatDrawer>;
  @ViewChild(MatSidenav) sidenav: MatSidenav;

  title = 'partner-portal';
  events: string[] = [];
  notificationSideNavExpanded = localStorage.getItem('notificationSidenavExpanded') === 'true' ? true : false;
  alerts: Alert[];
  unsupportedBrowser: boolean;
  isForbidden = false;
  supportedBrowsers = ['chrome', 'edge', 'safari'];
  routeContainsAuth: boolean;
  siteIsUpdated = false;
  private destroyed$ = new Subject<void>();
  openZenDesk: any;
  horizontalSnackBarPosition: MatSnackBarHorizontalPosition = 'end';
  verticalSnackBarPosition: MatSnackBarVerticalPosition = 'bottom';
  label = '';
  currentSnackbar: MatSnackBarRef<DecisioningSnackbarComponent> | null;
  imageSrc = '';
  importStatus = '';
  snackbarTriggeredForImportId: number;
  currentUser: AuthUser;
  hubSpotForm = false;
  pageTitle = computed(() => this._navTitle.pageTitle());
  funnelFeatureEnabled = false;
  secondaryPageTitle = computed(() => this._navTitle.secondaryPageTitle());

  constructor(
    public session: SessionService,
    public loadingService: LoadingService,
    private notificationService: NotificationService,
    private saasService: SaasFeaturesService,
    private store: Store,
    private pusherService: PusherService,
    private _breakpointObserver: BreakpointObserver,
    private _themeService: LendioAngularMaterialThemeService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _navTitle: NavPageTitleService,
    private _breadcrumbService: BreadcrumbService,
    private _snackBar: MatSnackBar,
    private snackbarService: LendioSnackbarService,
    private decisioningService: DecisioningService,
    private _formOptionsService: FormOptionsService,
    private _funnelEventsService: FunnelEventsService
  ) {
    this.localeInitializer();
    if (!environment.disableFullStory) {
      FullStory.init({
        orgId: 'nyB',
        devMode: !environment.production
      });
    }
    this.subscribeSiteUpdated();
  }

  @HostListener('window:mousemove')
  refreshUserState() {
    this.sessionTimeout$.next();
  }

  private sessionTimeout$ = new Subject<void>();

  /**
   * @return void
   */
  ngOnInit(): void {
    this.store.dispatch(new RestoreLocalStorageUser());

    this.currentUser$.pipe(takeUntil(this.destroyed$)).subscribe((currentUser: AuthUser) => {
      if (currentUser) {
        this.currentUser = currentUser;
        // this.hasBusinessListFeature$.subscribe(permitted => {
        //   if (permitted && currentUser.isLender()) {
        //     // Get a head start in getting/populating businesses counts into the store
        //     this.businessCountInitialized$.pipe(takeUntil(this.destroyed$)).subscribe(init => {
        //       if (!init) {
        //         this.store.dispatch(new GetBusinessCount());
        //       }
        //     });
        //   }
        // })
        this._themeService.setUserTheme(currentUser);
        if (this._themeService.oldThemeEnabled()) {
          this.store.dispatch(new LogEvent('legacyThemeLoaded', {}));
        }
      }
    })

    this.sessionTimeout$.pipe(takeUntil(this.destroyed$), throttleTime(1000)).subscribe(() => {
      this.session.setTimeout();
    });

    this.alerts$.pipe(takeUntil(this.destroyed$)).subscribe(data => {
      if (data) {
        this.alerts = data;
      }
    });

    const currentBrowser = this.getBrowserName();

    if (!currentBrowser || this.supportedBrowsers.indexOf(currentBrowser) === -1) {
      this.unsupportedBrowser = true;
    }

    this.checkForbidden();
    this.loadZenDeskWidgetScript();

    this.hasFunnelEarlyAccess$.pipe(takeUntil(this.destroyed$)).subscribe((enabled) => {
      this.funnelFeatureEnabled = enabled;
      this._funnelEventsService.setFunnelEnabled(enabled);
      catchError(() => {
        return of();
      })
    })

    this.hasDecisioningFeature$.pipe(takeUntil(this.destroyed$)).subscribe((enabled) => {
      catchError(() => {
        return of();
      })

      // If the user has the decisioning feature enabled
      if (enabled) {
        // Get batches
        if (this.currentUser && this.currentUser.institution?.id) {
          this.store.dispatch(new GetLenderDecisioningBatches(this.currentUser.institution.id));
        }
        // Subscribe to batch updates
        this.store.dispatch(new SubscribeToDecisioningBatchPusherUpdates());
        this.decisioningProgress$.pipe(takeUntil(this.destroyed$)).subscribe((decisioningProgress) => {
          catchError(() => {
            return of();
          })
          if (this.decisioningService.shouldShowDecisioningSnackbar(decisioningProgress) && !this.currentSnackbar) {
            this.currentSnackbar = this.snackbarService.open({
              component: DecisioningSnackbarComponent,
              undismissable: true,
              horizontalPosition: this.horizontalSnackBarPosition,
              verticalPosition: this.verticalSnackBarPosition,
            });
            this.currentSnackbar.afterDismissed().pipe(takeUntil(this.destroyed$)).subscribe(() => this.currentSnackbar = null);
          }
        }
        );
      }
    })
  }

  /**
   * @return void
   */
  ngAfterViewInit(): void {
    this._formOptionsService.hubSpotForm.pipe(takeUntil(this.destroyed$)).subscribe((bool) => {
      this.hubSpotForm = bool;
      if (bool) {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//js.hsforms.net/forms/shell.js';
        document.head.appendChild(script);
      }
    });
    this.notificationService.setSidenav(this.notificationSidenav.first);
    this.notificationSidenav.changes.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      // Initialize after view change too
      this.notificationSideNavExpanded = localStorage.getItem('notificationSidenavExpanded') === 'true' ? true : false;
      this.notificationService.setSidenav(this.notificationSidenav.first);
    });
    this.session.setTimeout();
  }

  /**
   * @return void
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this._snackBar.dismiss();
    this.store.dispatch(new UnsubscribeFromImportStateUpdates());
    this.currentSnackbar = null;
  }

  dismissAlert(alert: Alert) {
    this.store.dispatch(new DismissAlert(alert));
  }

  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(window as any).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  checkForbidden(): void {
    this.routeContainsAuth = window.location.pathname.includes('auth');
    if (sessionStorage.getItem('Forbidden') === null) {
      this.isForbidden = false;
    } else {
      this.isForbidden = true;
    }

    setTimeout(() => {
      this.checkForbidden();
    }, 200);
  }

  async localeInitializer(): Promise<any> {
    const locale = environment.locale;
    if (locale === 'en-US') {
      return;
    } else if (locale === 'en-GB') {
      await import(`@/../@angular/common/locales/${locale}.mjs`)
        .then(localeModule => registerLocaleData(localeModule.default));
    } else {
      const localePrefix = locale.substr(0, 2);
      await import(`@/../@angular/common/locales//${localePrefix}.mjs`)
        .then(localeModule => registerLocaleData(localeModule.default));
    }
  }

  subscribeSiteUpdated() {
    this.pusherService.subscribe({
      auth: false,
      name: 'site-updated',
      handler: () => {
        this.siteIsUpdated = true;
      }
    });
  }

  get institutionName() {
    return this.currentUser?.institution?.name;
  }

  get organizationName() {
    return this.currentUser?.affiliate?.name ?? this.currentUser?.institution?.name;
  }

  get notLendio() {
    return this.institutionName && this.institutionName != 'Lendio'
  }

  get canSeeEmbeddedConfig() {
    return (
      this.currentUser?.hasEmbeddedChildAffiliate()
      && (this.currentUser?.permittedTo('embeddedLegal.write') || this.currentUser?.permittedTo('embeddedConfig.write') || this.currentUser?.permittedTo('embeddedCTA.write') || this.currentUser?.permittedTo('embeddedWebhooks.write'))
    ) ?? false;
  }

  get showFunnelNavItem() {
    // todo remove check when this temporary permission is no longer needed
    return this.currentUser?.permittedTo('TEMP_portalFunnelNav');
  }

  get canSeeImports(): boolean {
    return (this.currentUser?.isLender() ?? false) && (this.currentUser?.permittedTo('lpxAdminPanel') || false);
  }

  get canSeeAdmin(): boolean {
    return this.saasService.saasPermitted('navigationAdministration', 'lpxNavigationAdministration.view') || (!this.currentUser?.isLender() && this.canSeeEmbeddedConfig) || !!(this.canSeeApi && this.newThemeEnabled);
  }

  get canSeeApi(): boolean {
    return (this.currentUser.permittedTo('lpxManageAPI') || false) || (this.currentUser.permittedTo('lpxManagePartnerAPI') || false);
  }

  get navCollapsed(): boolean {
    // automatically collapse nav at a "< 1440px" breakpoint
    return this._breakpointObserver.isMatched([
      LENDIO_BREAKPOINTS.extraLarge,
    ]);
  }

  get isSmallOrExtraSmall(): boolean {
    return this._breakpointObserver.isMatched([
      LENDIO_BREAKPOINTS.extraSmall,
      LENDIO_BREAKPOINTS.small
    ]);
  }

  get userFullName(): string {
    return this.currentUser?.fullName as string;
  }

  goToSettings(): void {
    this._router.navigate(['/settings'], { relativeTo: this._route });
  }


  shouldApplyToolbarBottomBorder(pageTitle: string): boolean {
    return !APPS_WITH_TABS.includes(pageTitle?.toLowerCase());
  }

  async forceLinkActive(rla) {
    await document.querySelectorAll('.lendio-nav-link')
      .forEach((navItem) => navItem.classList.remove('is-active'));
    rla.element.nativeElement.classList.add('is-active');
  }

  async forceElementActive(element) {
    await document.querySelectorAll('.lendio-nav-link')
      .forEach((navItem) => navItem.classList.remove('is-active'));
    setTimeout(() => element?.classList.add('is-active'));
  }

  navLinkIsActive(rla) {
    return rla?.element?.nativeElement?.classList?.contains('is-active');
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  private loadZenDeskWidgetScript() {
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${environment.zenDeskWidgetKey}`;
    script.async = true;
    script.id = 'ze-snippet'
    document.getElementsByTagName('head')[0].appendChild(script);
    this.openZenDesk = () => zE('messenger', 'open');
  }

}
