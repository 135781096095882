import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, catchError, combineLatest, of, takeUntil } from 'rxjs';
import { Decisioning } from '@app/app/interfaces/decisioning.model';
import { DecisioningState } from '@app/app/store/decisioning/decisioning.state';
import { SubscribeToDecisioningReRunResults, SubscribeToLenderDecisioning, UnsubscribeFromDecisioningReRunResults, UnsubscribeFromLenderDecisioning } from '@app/app/store/decisioning/decisioning.actions';
import { AuthState } from '@app/app/store/auth/auth.state';
import { AuthUser } from '@app/app/store/auth/auth-user';
import {
  MatDialog
} from '@angular/material/dialog';
import { DecisioningOverridesDialogComponent } from './overrides/decisioning-overrides-dialog.component';
import { Deal } from '@app/app/interfaces/deal.model';
import { QualifierValue } from '@app/app/interfaces/qualifier.model';
import { DecisioningResult } from '@app/app/interfaces/decisioning-result.model';
import { MatSnackBarHorizontalPosition, MatSnackBarRef, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { DecisioningSnackbarComponent } from '../decisioning-snackbar/decisioning-snackbar.component';
import { LendioSnackbarService } from '@app/app/services/lendio-snackbar.service';
import { DecisioningService } from '@app/app/services/decisioning.service';
import { EventBusService } from '@app/app/services/event-bus.service';

@Component({
  selector: 'app-decisioning',
  templateUrl: './decisioning.component.html',
  styleUrls: []
})

export class DecisioningComponent implements OnInit, OnDestroy {
  @Select(DecisioningState.decisioning) decisioning$: Observable<Decisioning[]>;
  @Select(DecisioningState.decisioningFailed) decisioningFailed$: Observable<boolean>;
  @Select(DecisioningState.decisioningResults) decisioningResults$: Observable<DecisioningResult[]>;
  @Select(DecisioningState.decisioningReRun) decisioningReRun$: Observable<DecisioningResult[]>;
  @Select(DecisioningState.isDecisioningReRunQueued) isDecisioningReRunQueued$: Observable<boolean>;
  @Select(DecisioningState.rejectedQualifiers) rejectedQualifiers$: Observable<QualifierValue[]>;
  @Select(DecisioningState.decisioningHasOverrides) decisioningHasOverrides$: Observable<boolean>;
  @Select(DecisioningState.isOverrideable) isOverrideable$: Observable<boolean>;
  @Select(DecisioningState.loading) loading$: Observable<boolean>;
  @Select(AuthState.user) currentUser$: Observable<AuthUser>;

  @Input() borrowerId: number;
  @Input() loanProductId: number;
  @Input() deal: Deal;
  @Input() autoPopOverrides = false;

  private destroyed$ = new Subject<void>();
  currentUser: AuthUser;
  rejectedQualifierValues: QualifierValue[] = [];
  decisioningHasOverrides: boolean = false;
  isOverrideable: boolean = false;
  currentSnackbar: MatSnackBarRef<DecisioningSnackbarComponent> | null;
  horizontalSnackBarPosition: MatSnackBarHorizontalPosition = 'end';
  verticalSnackBarPosition: MatSnackBarVerticalPosition = 'bottom';
  isReRunQueued: boolean = false;

  emptyType = 'decisioning';
  emptyLabelContent = 'Decisioning is in progress for this business.';
  emptyMessageContent = "We'll notify you when decisioning is complete.";

  constructor(
    private store: Store,
    public dialog: MatDialog,
    private snackbarService: LendioSnackbarService,
    private decisioningService: DecisioningService,
    private eventBusService: EventBusService
  ) {
  }

  /**
   * Ng lifecycle
   * @return void
   */
  ngOnInit(): void {
    this.currentUser$.pipe(takeUntil(this.destroyed$)).subscribe((currentUser: AuthUser) => {
      catchError(() => {
        return of();
      })
      if (currentUser) {
        this.currentUser = currentUser;
        this.store.dispatch(new SubscribeToLenderDecisioning(this.deal.id, this.borrowerId, this.currentUser?.institution?.id));
      }
    })

    this.rejectedQualifiers$.pipe(takeUntil(this.destroyed$)).subscribe((rejectedQualifiers: QualifierValue[]) => {
      catchError(() => {
        return of();
      })
      if (rejectedQualifiers) {
        this.rejectedQualifierValues = rejectedQualifiers;
        // If the user navigated to the Decisioning tab by clicking the "Override" button
        // in the deal side bar, automatically pop Overrides dialog.
        if (this.autoPopOverrides) {
          this.autoPopOverrides = false;
          this.openOverridesDialog();
        }
      }
    });

    this.decisioningHasOverrides$.pipe(takeUntil(this.destroyed$)).subscribe((decisioningHasOverrides: boolean) => {
      catchError(() => {
        return of();
      })
      if (decisioningHasOverrides) {
        this.decisioningHasOverrides = decisioningHasOverrides;
      }
    })

    this.isOverrideable$.pipe(takeUntil(this.destroyed$)).subscribe((isOverrideable: boolean) => {
      catchError(() => {
        return of();
      })
      if (isOverrideable) {
        this.isOverrideable = isOverrideable;
      }
    })

    this.isDecisioningReRunQueued$.pipe(takeUntil(this.destroyed$)).subscribe(isQueued => {
      catchError(() => {
        return of();
      })
      this.isReRunQueued = isQueued
    })

    combineLatest([
      this.decisioningFailed$,
      this.decisioningResults$
    ]).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(([decisioningFailed, decisioningResults]) => {
      catchError(() => {
        return of();
      })
      if (decisioningFailed) {
        const [result] = decisioningResults.filter(result => result.loanProductId == this.loanProductId); // while not used as of 10SEP24, this will be integral for error mapping
        this.emptyLabelContent = 'Decisioning encountered an error.  ';
        this.emptyMessageContent = 'Please contact Lendio.';
      }
    })

    this.store.dispatch(new SubscribeToDecisioningReRunResults());
    this.handleDecisioningReRunSnackbar();

    this.eventBusService
      .subscribe(this.eventBusService.types.OpenOverridesEvent)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.openOverridesDialog());
  }

  /**
   * Ng lifecycle
   * @return void
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.store.dispatch(new UnsubscribeFromDecisioningReRunResults());
    this.store.dispatch(new UnsubscribeFromLenderDecisioning(this.borrowerId, this.currentUser?.institution?.id));
    this.currentSnackbar = null;
  }

  /**
   * @return void
   */
  openOverridesDialog(): void {
    const dialogRef = this.dialog.open(DecisioningOverridesDialogComponent, {
      disableClose: true,
      data: {
        'deal': this.deal,
        'rejectedQualifierValues': this.rejectedQualifierValues,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.handleDecisioningReRunSnackbar();
    });
  }

  /**
   * Determine whether to operate the decisioning snackbar
   * @return void
   */
  handleDecisioningReRunSnackbar(): void {
    this.decisioningReRun$.pipe(takeUntil(this.destroyed$)).subscribe((decisioningResult) => {
      catchError(() => {
        return of();
      });
      if (
        (this.decisioningService.shouldShowReRunSnackbar(decisioningResult)
          && !this.currentSnackbar)
        || (this.isReRunQueued && !this.currentSnackbar)
      ) {
        this.currentSnackbar = this.snackbarService.open({
          component: DecisioningSnackbarComponent,
          undismissable: false,
          horizontalPosition: this.horizontalSnackBarPosition,
          verticalPosition: this.verticalSnackBarPosition
        });
        this.currentSnackbar.afterDismissed().pipe(takeUntil(this.destroyed$)).subscribe(() => this.currentSnackbar = null);
      }
    })
  }
}
